<template>
  <section class="systems">
    <template v-if="allSystems.length !== 0">
      <div class="container container__title">
        <div class="systems__title major-title">
          {{ $t('message.systems.title') }}
        </div>
        <app-input
            v-model="fastSearch"
            @input="showFilteredProducts()"
            :type="'search'"
            :isError="false"
            :field="{ name: 'search-system'}"
            :icon="true">
        </app-input>
      </div>
      <Products-filter
        :products="allSystems"
        :categories="systemsCategories"
        :normalizedCategories="normalizedCategories"
        @returnFilteredProducts="showFilteredProducts"
      />
      <div class="container">
        <Products-view
          :products="productsToProductView"
          placement="system"
          @productSelected="updateSectorSystem"
          @createCustom="createCustomSystem"
        >
          <template #image>
            <img src="@/assets/img/icons/product--create.svg" alt="create" />
          </template>
          <i18n path="message.systems.create" class="create-btn__text">
            <template #break>
              <br />
            </template>
          </i18n>
        </Products-view>
      </div>
    </template>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import ProductsView from '@/components/smart/products/Products-view'
import ProductsFilter from '@/components/smart/products/Products-filter'
import AppInput from '@/components/dump/AppInput'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { getItem } from '@/api'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  data: () => ({
    filteredProducts: [],
    isLoading: false,
    isFiltered: false,
    fastSearch: ''
  }),
  components: {
    AppInput,
    ProductsView,
    ProductsFilter
  },
  methods: {
    ...mapActions({
      updateStep: 'updateStep',
      fetchData: 'fetchData',
      checkTNTokenAction: 'checkTNToken',
      updateTNToken: 'updateTNToken'
    }),
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM: 'UPDATE_SECTOR_SYSTEM',
      CREATE_CUSTOM_SYSTEM: 'CREATE_CUSTOM_SYSTEM'
    }),
    updateSectorSystem(id) {
      this.isLoading = true
      getItem(this.$i18n.locale, 'system', id).then(response => {
        this.UPDATE_SECTOR_SYSTEM({
          index: this.sectorIndex,
          systemId: id,
          data: response.data
        })
        this.isLoading = false
        this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
      })
    },
    createCustomSystem() {
      this.CREATE_CUSTOM_SYSTEM(this.sectorIndex)
      this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
    },
    showFilteredProducts(products = this.isFiltered ? this.filteredProducts : this.allSystems) {
      this.isFiltered = true
      this.filteredProducts = products
    },
    checkTNToken() {
      const token = this.$route.query.tnt
      if (token) {
        this.checkTNTokenAction(token, this.$i18n.locale)
      } else {
        this.updateTNToken()
      }
    }
  },
  mounted() {
    if (this.allSystems.length === 0) {
      this.checkTNToken()
      this.fetchData(this.$i18n.locale)
    }
    this.updateStep(stepNumbers.systems)
  },
  computed: {
    ...mapState({
      systemsCategories: state => state.systemsCategories,
      responseLayers: state => state.layers
    }),
    ...mapGetters({
      allSystems: 'allPublishedSystems'
    }),
    normalizedCategories() {
      return Object.fromEntries(this.systemsCategories.map(c => [c.id, []]))
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    productsToProductView() {
      if (this.isFiltered) {
        if (this.fastSearch.trim() !== '') {
          return this.filteredProducts.filter((system) => system.name.toLowerCase().includes(this.fastSearch.trim()))
        }
        return this.filteredProducts
      }

      return this.allSystems
    }
  },
  name: 'Systems'
}
</script>

<style scoped lang="sass"></style>
